import { Col, Row } from "../Grids/style";
import { ContainerBanner } from "./style";

const BannerHome = () => {
  return (
    <ContainerBanner>
      <div className="container">
        <Row>
          <Col size={"8"}>
            <h1>Seja bem-vindo ao site do Projeto Plantar!</h1>
            <h2>
              Aqui você encontra informações sobre o projeto e nossas
              iniciativas.
            </h2>
          </Col>
        </Row>
      </div>
    </ContainerBanner>
  );
};

export default BannerHome;
