// LGPDAlert.js
import React, { useState, useEffect } from "react";
import {
  AlertContainer,
  AlertText,
  AlertLink,
  ButtonContainer,
  AcceptButton,
} from "./style";

const LGPDAlert = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem("lgpdAccepted");
    if (!isAccepted) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("lgpdAccepted", "true");
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <AlertContainer>
      <AlertText>
        Este site utiliza cookies para melhorar a experiência do usuário. Para
        mais informações, acesse nossa
        <AlertLink to="/politica-de-privacidade">
          Política de Privacidade
        </AlertLink>
        .
      </AlertText>
      <ButtonContainer>
        <AcceptButton onClick={handleAccept}>
          Li e aceito os termos
        </AcceptButton>
      </ButtonContainer>
    </AlertContainer>
  );
};

export default LGPDAlert;
