import Seo from "../../shared/components/Seo";
import BannerHome from "../../shared/components/BannerHome";
import { ContentSection } from "./style";
import BemVindo from "./BemVindo";

const Home = () => {
    
    return (
        <ContentSection>
            <Seo 
                titlePage={`Projeto Plantar`}
                description={'O Projeto Plantar é uma iniciativa que organiza o plantio de árvores nativas em áreas degradadas ou com alta fragilidade ambiental, visando a recuperação ambiental e o sequestro de carbono. O projeto também busca sensibilizar a população sobre a importância das árvores para a conservação do meio ambiente.'}
                keywordsPages={'Árvores, plantio, recuperação ambiental, sensibilização, meio ambiente, conservação'}
                urlPage={'https://www.projetoplantar.com.br/'}
                imagePage={'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'}
            />
            <BannerHome />
            <BemVindo />
        </ContentSection>
    );
};

export default Home ;