import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { Row, Col } from "../Grids/style";
import { ContainerFooter, CopyFooter, MenuFooter, RedesSociais } from "./style";
import LogoFooter from "../../../assets/imgs/logo.png";

const Footer = () => {
  return (
    <ContainerFooter>
      <div className="container">
        <Row>
          <Col size="4">
            <img src={LogoFooter} alt="Logo Plantar" />
            <h3>Contatos:</h3>
            <p>Email: mandaproandre@hotmail.com</p>
            <p>Telefone: +55 37 8418-2692</p>
            <p>Celular: +55 37 8418-2692</p>
            <p>Endereço: R. Vice Pref. Totonho Batista, 425 - São Francisco, São Roque de Minas - MG</p>
          </Col>
          <Col size="4">
            <h3>Navegação:</h3>
            <MenuFooter>
              <li>
                <Link to={"/"}>Página Inicial</Link>
              </li>
              <li>
                <Link to={"/conheca-a-plantar"}>A Plantar</Link>
              </li>
              <li>
                <Link to={"/"}>Nossas iniciativas</Link>
              </li>
              <li>
                <Link to={"/"}>Plante uma árvore</Link>
              </li>
              <li>
                <Link to={"/"}>Login Plataforma Plantar</Link>
              </li>
            </MenuFooter>
          </Col>
          <Col size="4">
            <h3>Siga nossas redes sociais:</h3>
            <RedesSociais>
              <li>
                <a
                  href="https://www.facebook.com/projetoplantar.com.br"
                  title="Acessar Facebook"
                >
                  <FaFacebookSquare />
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/projeto.plantar"
                  title="Acessar Instagram"
                >
                  <FaInstagramSquare />
                  Instagram
                </a>
              </li>
            </RedesSociais>
          </Col>
          <Col size="4"></Col>
        </Row>
      </div>
      <CopyFooter textAlign={"center"}>
        <Row>
          <Col size="12">
            <p>
              © 2024 - Todos os direitos reservados Plantar | Desenvolvimento:{" "}
              <a
                href="https://velance.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                Velance
              </a>
            </p>
          </Col>
        </Row>
      </CopyFooter>
    </ContainerFooter>
  );
};

export default Footer;
