import styled from "styled-components";

const ContentPost = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .container {
    margin: 0 auto;
    width: 99%;
    padding: 8em 0;
    width: 90%;
    h2 {
      font-size: 3em;
    }
    img {
      border-radius: 10px;
    }
    small {
      display: inline-block;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.2em;
    }
  }
  @media (min-width: 768px) {
    .container {
      padding: 8em 0;
      width: 90%;
      h2 {
        font-size: 2.5em;
      }
      img {
        border-radius: 10px;
      }
      small {
        display: inline-block;
        margin-bottom: 30px;
      }
      p {
        font-size: 1.2em;
      }
    }
  }
`;

export { ContentPost };
