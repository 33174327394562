import angicoBranco from '../../assets/imgs/plantas/angico-branco.png'
import angicoVermelho from '../../assets/imgs/plantas/angico-vermelho.png'
import jacarandaCerrado from '../../assets/imgs/plantas/jacarandaCerrado.png'

const plantasMock = [
    {
      especie: "Angico Branco",
      alturaInicial: "70cm",
      alturaMedia: "4 metros",
      alturaFinal: "30 metros",
      caracteristicas:
        "Arvoreta perenifólia de Angico Branco, com 2,2 a 15 m de altura e 20 a 40 cm de diâmetro, a árvore com até 25 m de altura e 60 cm de diâmetro. Folhas com folíolos coriáceos, nítidos, freqüentemente falcados, pinas com 10 a 18 jugos, folíolos com 40 a 60 jugos, uninervados, nítidos e glabros. As flores são reunidas em inflorescências e os frutos são de coloração marrom, com 10 a 25 cm de comprimento e 17 a 25 mm de largura, contendo entre 10 e 15 sementes.",
      imagem: angicoBranco,
    },
    {
      especie: "Angico Vermelho do Cerrado",
      alturaInicial: "80cm",
      alturaMedia: "5 metros",
      alturaFinal: "20 metros",
      caracteristicas:
        "Árvore de porte médio a grande, com tronco grosso e casca rugosa de coloração acinzentada. Suas folhas são compostas, bipinadas e pequenas, enquanto as flores são pequenas e esbranquiçadas, formando inflorescências. É conhecida por sua madeira resistente e de cor avermelhada.",
      imagem: angicoVermelho,
    },
    {
      especie: "Jacarandá do Cerrado",
      alturaInicial: "60cm",
      alturaMedia: "3 metros",
      alturaFinal: "15 metros",
      caracteristicas:
        "Árvore de tronco reto e madeira nobre, famosa por sua floração exuberante de cor lilás. Suas folhas são compostas e bipinadas, caindo durante a estação seca. O jacarandá é apreciado pela beleza de sua floração e a resistência de sua madeira.",
      imagem: jacarandaCerrado,
    },
    {
      especie: "Sucupira do Cerrado",
      alturaInicial: "90cm",
      alturaMedia: "4 metros",
      alturaFinal: "18 metros",
      caracteristicas:
        "Árvore de madeira densa e escura, conhecida pela resistência e durabilidade. Suas folhas são simples e alternadas, e as flores são pequenas e amareladas. A sucupira é valorizada também por suas propriedades medicinais.",
      imagem: "https://example.com/sucupira-cerrado.jpg",
    },
    {
      especie: "Ipê Branco",
      alturaInicial: "70cm",
      alturaMedia: "5 metros",
      alturaFinal: "25 metros",
      caracteristicas:
        "Árvore que se destaca pela bela floração branca, geralmente no final do inverno e início da primavera. Suas folhas são compostas e palmadas, e a madeira é resistente e durável. É muito utilizada na arborização urbana devido à sua beleza.",
      imagem: "https://example.com/ipe-branco.jpg",
    },
    {
      especie: "Ipê Roxo",
      alturaInicial: "60cm",
      alturaMedia: "6 metros",
      alturaFinal: "30 metros",
      caracteristicas:
        "Árvore de porte médio a grande, conhecida por suas flores roxas vibrantes que aparecem no final da estação seca. Possui folhas compostas e pequenas, e sua madeira é de alta qualidade, muito utilizada em construções e mobiliário.",
      imagem: "https://example.com/ipe-roxo.jpg",
    },
  ];
  
  export default plantasMock;
  