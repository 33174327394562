import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Seo from "../../shared/components/Seo";
import { Content, CardPost } from "./style";

import { Row, Col } from "../../shared/components/Grids/style";
import { fetchBlogs } from "../../service/api";
import { BASE_URL } from "../../service/base_url";
const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getBlogs = async () => {
      const data = await fetchBlogs();
      setBlogs(data);
    };
    getBlogs();
  }, []);
  return (
    <Content>
      <Seo
        titlePage={`Projeto Plantar`}
        description={
          "Confira nossos posts sobre práticas de sustentabilidade e dicas variadas para se conectar com a natureza"
        }
        keywordsPages={
          "Árvores, plantio, recuperação ambiental, sensibilização, meio ambiente, conservação"
        }
        urlPage={"https://www.projetoplantar.com.br/conheca-a-plantar"}
        imagePage={"https://www.projetoplantar.com.br/social-plantar.png"}
      />
      <div className="container">
        <Row>
          {blogs.map((blog) => (
            <Col size={4} key={blog.id}>
              <CardPost>
                <img src={`${BASE_URL}/uploads/imgs/blog/${blog.imagem}`} alt={blog.titulo} />
                <small>
                  Data da postagem:{" "}
                  {new Date(blog.data_postagem).toLocaleDateString()}
                </small>
                <h2>{blog.titulo}</h2>
                <p>{blog.descricao}</p>
                <Link to={`/postagem/${blog.slug}`}>Leia mais</Link>
              </CardPost>
            </Col>
          ))}
        </Row>
      </div>
    </Content>
  );
};

export default Blog;
