import { ContainerMenu } from "./style"
import Links from "./Links";
import Logo from "./Logo";

const Menu = () => {
    return(
        <ContainerMenu>
            <div className="content-menu">
                <Logo />
                <Links />
            </div>
        </ContainerMenu>
    )
}

export default Menu;