import React from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import{
    Container,
    Icon,
    Title,
    Message,
    Button
} from './style'

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Container>
      <Icon>
        <FaExclamationTriangle />
      </Icon>
      <Title>404</Title>
      <Title>Oops! Conteúdo não encontrado.</Title>
      <Message>Desculpe, mas o conteúdo solicitado não foi encontrado.</Message>
      <Button onClick={handleGoBack}>Voltar para a página inicial</Button>
    </Container>
  );
};

export default ErrorPage;


