import { Routes, Route } from "react-router-dom";

import Home from "./views/Home";
import Plantar from "./views/Plantar";
import Postagem from "./views/Postagem";
import Blog from "./views/Blog";
import ErrorPage from "./views/Error";
import PoliticasLGPD from "./views/PoliticasLGPD";
import PlanteUmaArvore from "./views/PlanteUmaArvore";
import Layout from "./Layout";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="conheca-a-plantar" element={<Plantar />} />
        <Route path="plante-uma-arvore" element={<PlanteUmaArvore />} />
        <Route path="politica-de-privacidade" element={<PoliticasLGPD />} />
        <Route path="blog" element={<Blog />} />
        <Route path="postagem/:slug" element={<Postagem />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default App;
