import styled from "styled-components";
import imgBannerSobre from "../../assets/imgs/a-plantar.jpg";
import fundoArvore from "../../assets/imgs/fundo-arvore.png";

const BannerPlantar = styled.section`
  background-image: url(${imgBannerSobre});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    .container {
      color: #fff;
      margin: 0 auto;
      width: 99%;
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (min-width: 768px) {
    .overlay {
      .container {
        padding: 8em 0;
        width: 90%;
        h1 {
          font-size: 39px;
          line-height: 58px;
          width: 90%;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .overlay {
      .container {
        padding: 8em 0;
        width: 90%;
        h1 {
          font-size: 39px;
          line-height: 58px;
          width: 90%;
        }
      }
    }
  }
`;

const ContainerSobre = styled.section`
  display: flex;
  flex-direction: column;
`;

const ContentSobre = styled.main`
  display: flex;
  flex-direction: column;
  .container {
    margin: 0 auto;
    width: 99%;
  }
  @media (min-width: 768px) and (max-height: 1399px) {
    background-image: url(${fundoArvore});
    background-position-y: 710px;
    background-repeat: no-repeat;
    background-size: 100%;
    .container {
      padding: 4em 0;
      width: 90%;
      h2 {
        font-size: 2em;
      }
      p{
        font-size: 1.6em;
        margin-top: 50px;
        width: 80%;
      }
    }
  }
  @media (min-width: 1400px) {
    background-image: url(${fundoArvore});
    background-position-y: 120px;
    background-repeat: no-repeat;
    background-size: 100%;
    .container {
      padding: 4em 0;
      width: 90%;
      h2 {
        font-size: 2em;
      }
      p{
        font-size: 1.6em;
        margin-top: 50px;
        width: 80%;
      }
    }
  }
`;

export { BannerPlantar, ContainerSobre, ContentSobre };
