import { Outlet } from "react-router-dom";
import Footer from "./shared/components/Footer";
import styled from "styled-components";
import Menu from "./shared/components/Menu";
import LGPDAlert from "./shared/components/AlertLGPD";

const Theme = styled.div`
  background-color: #fff;
  min-height: 100svh;
  width: 100%;
`;

const Layout = () => (
  <Theme>
    <Menu />
    <Outlet />
    <LGPDAlert />
    <Footer />
  </Theme>
);

export default Layout;