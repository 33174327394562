import React from "react";
import { ContentBemVindo } from "./style";
import { Col, Row } from "../../shared/components/Grids/style";

const BemVindo = () => {
  return (
    <ContentBemVindo>
      <div className="container">
        <Row>
          <Col size="6">
            <h2>Olá, nós somos a Plantar uma empresa amiga da natureza</h2>
            <p>O Projeto Plantar é uma iniciativa que organiza o plantio de árvores nativas em áreas degradadas ou com alta fragilidade ambiental. Seu objetivo é promover a recuperação dessas áreas e sensibilizar a população sobre a importância das árvores para a conservação do meio ambiente e o sequestro de carbono.</p>
          </Col>
          <Col size="6">
            <img src="https://img.freepik.com/fotos-premium/salve-o-planeta-plantando-arvores_1049089-2.jpg" alt="Imagem plantio" />
          </Col>
        </Row>
      </div>
    </ContentBemVindo>
  );
};

export default BemVindo;
