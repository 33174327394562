import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px;
`;

const Icon = styled.div`
  font-size: 60px;
  color: #ff6b6b;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 32px;
  color: #343a40;
  margin-bottom: 10px;
`;

const Message = styled.p`
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

export{
    Container,
    Icon,
    Title,
    Message,
    Button
}