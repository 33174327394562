import Seo from "../../shared/components/Seo";
import { BannerPlantar, ContainerSobre, ContentSobre } from "./style";
import { Col, Row } from "../../shared/components/Grids/style";


const Plantar = () => {
  return (
    <ContainerSobre>
      <Seo
        titlePage={`Projeto Plantar`}
        description={
          "Somos o Projeto Plantar, organizamos uma teia de plantio de árvores nativas em áreas degradadas"
        }
        keywordsPages={
          "Árvores, plantio, recuperação ambiental, sensibilização, meio ambiente, conservação"
        }
        urlPage={"https://www.projetoplantar.com.br/conheca-a-plantar"}
        imagePage={"https://www.projetoplantar.com.br/social-plantar.png"}
      />
      <BannerPlantar>
        <div className="overlay">
          <div className="container">
            <Row>
              <Col size={12}>
                <h1>
                  Somos o Projeto Plantar, organizamos uma teia de plantio de
                  árvores nativas em áreas degradadas
                </h1>
              </Col>
            </Row>
          </div>
        </div>
      </BannerPlantar>
      <ContentSobre>
        <div className="container">
          <Row>
            <Col size={12}>
              <h2>Nossa história</h2>
              <p>
                O Projeto Plantar é uma iniciativa que nasceu da vontade de
                contribuir para a restauração ambiental e a preservação do nosso
                planeta. Nosso foco é o plantio de árvores nativas em áreas
                degradadas e em regiões que apresentam situações de fragilidade
                ambiental, buscando reverter danos causados ao meio ambiente e
                fortalecer os ecossistemas locais. Com ações de reflorestamento,
                queremos promover a recuperação dessas áreas, ajudando a
                restabelecer a biodiversidade e criando habitats favoráveis para
                a fauna e flora locais.
              </p>
              <p>
                Acreditamos que as árvores desempenham um papel crucial no
                equilíbrio do meio ambiente, atuando como agentes de sequestro
                de carbono e melhorando a qualidade do ar e do solo. Por meio de
                nossas atividades, também contribuímos para a redução dos
                impactos das mudanças climáticas, criando um futuro mais
                sustentável e saudável para as próximas gerações.
              </p>
              <p>
                Nosso trabalho vai além do plantio. Procuramos sensibilizar e
                conscientizar a população sobre a importância das árvores e da
                preservação do meio ambiente, promovendo atividades educativas e
                de engajamento que incentivem a participação ativa de todos.
                Organizamos mutirões, eventos de conscientização e ações
                comunitárias para que as pessoas possam se envolver de forma
                direta na transformação ambiental.
              </p>
              <p>
                O Projeto Plantar se apoia em parcerias com empresas,
                organizações não governamentais, instituições de ensino e
                voluntários que compartilham da nossa visão de um mundo mais
                verde e equilibrado. Juntos, podemos plantar sementes de
                esperança e colaborar para um futuro onde a harmonia entre a
                natureza e as comunidades humanas seja uma realidade. Venha
                fazer parte desse movimento e ajude-nos a criar um impacto
                positivo e duradouro no meio ambiente.
              </p>
            </Col>
          </Row>
        </div>
      </ContentSobre>
    </ContainerSobre>
  );
};

export default Plantar;
