import styled from "styled-components";
import imageBackgroundHome from "../../../assets/imgs/background-home.svg";

const ContainerBanner = styled.section`
  background-image: url(${imageBackgroundHome});
  background-position-x: 20em;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  .container {
    margin: 0 auto;
    width: 99%;
    h1 {
      font-size: 2.5rem;
      color: var(--color-white);
    }

    p {
      font-size: 1.2rem;
      color: var(--color-white);
    }
  }
  @media (min-width: 768px) {
    min-height: 100svh;
    .container {
      padding-top: 10rem;
      width: 90%;
      h1 {
        font-size: 4rem;
      }
      h2 {
        font-size: 2rem;
        margin-top: 30px;
        width: 700px;
      }
    }
  }
  @media (min-width: 1400px) {
    min-height: 100svh;
    .container {
      padding-top: 10rem;
      width: 90%;
      h1 {
        font-size: 70px;
        width: 800px;
      }
      h2 {
        font-size: 46px;
        margin-top: 20px;
        width: 84%;
      }
    }
  }
`;

export { ContainerBanner };
