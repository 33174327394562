import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Seo from "../../shared/components/Seo";
import { fetchBlogBySlug } from "../../service/api";
import { BASE_URL } from "../../service/base_url";
import { Row, Col } from "../../shared/components/Grids/style";
import { ContentPost } from "./style";

function Postagem() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlog = async () => {
      try {
        const data = await fetchBlogBySlug(slug);
        if (data && data.id) {
          setBlog(data);
        } else {
          setError("Postagem não encontrada.");
        }
      } catch (e) {
        setError("Erro ao carregar a postagem.");
        console.error(e);
      }
    };
    getBlog();
  }, [slug]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!blog) {
    return <p>Carregando...</p>;
  }

  return (
    <ContentPost>
      <Seo
        titlePage={`Projeto Plantar - ${blog.titulo}`}
        description={blog.descricao}
        keywordsPages={
          "Árvores, plantio, recuperação ambiental, sensibilização, meio ambiente, conservação"
        }
        urlPage={`https://www.projetoplantar.com.br/${blog.titulo}`}
        imagePage={`${BASE_URL}${blog.imagem}`}
      />
      <div className="container">
        <Row>
          <Col size={12}>
            <div className="blog-detail">
              <img
                src={`${BASE_URL}/uploads/imgs/blog/${blog.imagem}`}
                alt={blog.titulo}
              />
              <h2>{blog.titulo}</h2>
              <small>
                Data de postagem: {new Date(blog.data_postagem).toLocaleDateString()}
              </small>
              {/* <small>
                {blog.autor}
              </small> */}
              <div className="content-text" dangerouslySetInnerHTML={{ __html: blog.texto }} />
            </div>
          </Col>
        </Row>
      </div>
    </ContentPost>
  );
}

export default Postagem;
