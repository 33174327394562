import styled from "styled-components";

const ContentLGPD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: #f0f0f0;
  .container{
    margin: 0 auto;
    width: 99%;
  }
  @media(min-width: 768px){
    .container{
      padding: 5em 0;
      width: 90%;
      h2{
        font-size: 2em;
      }
      h3{
        font-size: 1.6rem;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      p{
        font-size: 26px;
      }
      ul{
        li{
          font-size: 1.4rem;
        }
      }
    }
  }
`;

export { ContentLGPD };
