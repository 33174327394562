import styled from 'styled-components';
import { Link } from 'react-router-dom';

const AlertContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  color: #333;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  padding: 15px;
  z-index: 1000;
  width: 100%;
`;

const AlertText = styled.p`
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  margin: 0;
  text-align: left;
`;

const AlertLink = styled(Link)`
  color: #0c3249;
  font-weight: bold;
  margin-left: 5px;
  text-decoration: underline;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AcceptButton = styled.button`
  background-image: linear-gradient(to top, #0c3249 0%, #117b4e 100%);
  color: #fff;
  border: none;
  padding: 10px 2em;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
`;

export{
    AlertContainer,
    AlertText,
    AlertLink,
    ButtonContainer,
    AcceptButton   
}