import styled from "styled-components";

const getWidthCol =(value) =>{
    if(!value) return;

    let width = (value / 12) * 100;
    return `width: ${width}%`;
}

const Col = styled.div`
    padding: 1em;
    @media(min-width: 768px){
        ${({ size }) => size && getWidthCol(size)};
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media(min-width: 768px){
        flex-direction: row;
    }
`;

export{
    Col,
    Row
}