import styled from "styled-components";

const Content = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 61.5svh;
  .container {
    margin: 0 auto;
    padding: 20px;
    width: 99%;
  }
  @media (min-width: 768px) {
    .container {
      padding: 8em 0;
      width: 90%;
    }
  }
`;

const CardPost = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  h2 {
    line-height: 30px;
  }
  p {
   font-size: 1em;
  }
  small {
    margin-top: 10px;
  }
  a {
    background-color: #007bff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0.76em 2em;
    text-decoration: none;
  }
`;

export{
    Content,
    CardPost
}