import styled from "styled-components";

const ContainerMenu = styled.div`
  background-color: #fff;
  position: fixed;
  width: 100%;
  .content-menu {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em 1em;
  }
  @media (min-width: 768px) {
    .content-menu {
      gap: 5em;
    }
  }
`;

const ContainerLogo = styled.div`
  a {
    img {
      width: 10em;
    }
  }
`;

const ContainerLinks = styled.ul`
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  li {
    a {
      color: #333;
      cursor: pointer;
      font-size: 1em;
      font-weight: 600;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 21px;
    li {
      &:nth-child(6) {
        background-image: linear-gradient(to top, #117b4e 0%, #0c3249 100%);
        border-radius: 0.5em;
        padding: 0.8em 2em;
        a {
          color: #fff;
        }
      }
      a {
        font-size: 1em;
      }
    }
  }
  @media (min-width: 1400px) {
    flex-direction: row;
    gap: 4em;
    li {
      &:nth-child(6) {
        padding: 0.8em 2em;
      }
      a {
        font-size: 1em;
      }
    }
  }
`;

const LanguageSelect = styled.select`
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  padding: 5px;
`;

export { ContainerMenu, ContainerLogo, ContainerLinks, LanguageSelect };
