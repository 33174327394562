import styled from "styled-components";

const ContainerFooter = styled.footer`
  background-color: #fff;
  color: #333;
  .container {
    margin: 0 auto;
    padding: 1rem;
    width: 99%;
    @media (min-width: 768px) {
      width: 93%;
      h3 {
        font-size: 1.5rem;
      }
      img{
        width: 40%;
      }
      p {
        &:nth-child(4) {
          border-bottom: 1px solid #fff;
        }
      }
    }
    @media (min-width: 1400px) {
      max-width: 91%;
      min-width: 90%;
      h3 {
        font-size: 1.5rem;
      }
      img{
        width: 40%;
      }
      p {
        &:nth-child(4) {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
`;

const MenuFooter = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  li {
    a {
      color: #333;
      font-weight: 600;
      text-decoration: none;
    }
  }
`;
const RedesSociais = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  li {
    a {
      color: #333;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      gap: 0.5rem;
      text-decoration: none;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 0;
    li {
      a {
        font-size: 1.5rem;
        gap: 0.5rem;
        text-decoration: none;
      }
    }
  }
`;

const CopyFooter = styled.div`
  background-color: #1b3734;
  color: #fff;
  padding: 1rem;
  text-align: ${(props) => props.textAlign || 'left'};
  a {
    color: #8bc34a;
    font-weight: 600;
    margin-left: 0.3em;
  }
`;

export { ContainerFooter, CopyFooter, MenuFooter, RedesSociais };
