import { Link } from "react-router-dom";
import logoMenu from "../../../assets/imgs/logo.png"
import { ContainerLogo } from "./style";

const Logo = () => {
  return (
    <ContainerLogo>
      <Link to={"/"} title="logo Projeto Plantar">
        <img src={logoMenu} alt="logo Projeto Plantar" />
      </Link>
    </ContainerLogo>
  );
};

export default Logo;
