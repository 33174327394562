const BASE_URL = 'https://api.projetoplantar.com.br'; 

export const fetchBlogs = async () => {
    try {
        const response = await fetch(`${BASE_URL}/lista-blog.php`);
        if (!response.ok) {
            const errorMessage = await response.text();
            console.error(`Erro na API: ${errorMessage}`);
            throw new Error('Erro ao buscar blogs');
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const fetchBlogBySlug = async (slug) => {
    try {
        const response = await fetch(`${BASE_URL}/post-blog.php?slug=${slug}`);
        if (!response.ok) {
            const errorMessage = await response.text(); 
            console.error(`Erro na API: ${errorMessage}`);
            throw new Error('Erro ao buscar o blog');
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        return null;
    }
};
