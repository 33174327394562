import { useState } from "react";
import axios from "axios";
import InputMask from "react-input-mask";
import DOMPurify from "dompurify";
import { Col, Row } from "../../shared/components/Grids/style";
import { ContentPlanteUmArvore, BannerPlanteUmArvore } from "./style";

import plantasMock from "./mock";
const urlHost = "https://api.projetoplantar.com.br/pedidos-mudas.php";

const PlanteUmaArvore = () => {
  const [selectedEspecie, setSelectedEspecie] = useState(null);
  const [formData, setFormData] = useState({
    quantidade: "",
    nome: "",
    email: "",
    telefone: "",
    especie: "",
  });

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.value;
    if (selectedIndex === "0") {
      setSelectedEspecie(null);
      setFormData({ ...formData, especie: "" });
    } else {
      const selectedPlant = plantasMock[selectedIndex - 1];
      setSelectedEspecie(selectedPlant);
      setFormData({ ...formData, especie: selectedPlant.especie });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: DOMPurify.sanitize(value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        !formData.quantidade ||
        !formData.nome ||
        !formData.email ||
        !formData.telefone ||
        !formData.especie
      ) {
        alert("Por favor, preencha todos os campos.");
        return;
      }

      const response = await axios.post(
        `${urlHost}`,
        new URLSearchParams(formData), // Formatação adequada para envio dos dados
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded", // Definição do tipo de conteúdo
          },
        }
      );

      if (response.data.message) {
        alert(response.data.message);
      } else if (response.data.error) {
        alert(response.data.error);
      }
    } catch (error) {
      console.error("Erro ao enviar pedido:", error);
      alert("Ocorreu um erro ao enviar o pedido.");
    }
  };

  return (
    <ContentPlanteUmArvore>
      <BannerPlanteUmArvore>
        <div className="container">
          <Row>
            <Col size={6}>
              <h2>Plante uma árvore</h2>
              <h3>Solicite abaixo a espécie</h3>
              <form onSubmit={handleSubmit}>
                <div className="container-input">
                  <select onChange={handleSelectChange}>
                    <option value="0">Selecione a espécie</option>
                    {plantasMock.map((planta, index) => (
                      <option key={index} value={index + 1}>
                        {planta.especie}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="container-imagem">
                  {selectedEspecie && (
                    <div className="card-plant">
                      <img
                        src={selectedEspecie.imagem}
                        alt={DOMPurify.sanitize(selectedEspecie.especie)}
                      />
                      <h4>{selectedEspecie.especie}</h4>
                      <p>
                        <strong>Altura Inicial:</strong>{" "}
                        {selectedEspecie.alturaInicial}
                      </p>
                      <p>
                        <strong>Altura Média:</strong>{" "}
                        {selectedEspecie.alturaMedia}
                      </p>
                      <p>
                        <strong>Altura Final:</strong>{" "}
                        {selectedEspecie.alturaFinal}
                      </p>
                      <p>{selectedEspecie.caracteristicas}</p>
                    </div>
                  )}
                </div>
                <div className="container-input">
                  <input
                    type="number"
                    placeholder="Quantidade de mudas"
                    name="quantidade"
                    value={formData.quantidade}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="container-input">
                  <input
                    type="text"
                    placeholder="Nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="container-input">
                  <input
                    type="text"
                    placeholder="E-mail"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="container-input">
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleInputChange}
                  />
                </div>
                <button type="submit">Enviar</button>
              </form>
            </Col>
          </Row>
        </div>
      </BannerPlanteUmArvore>
    </ContentPlanteUmArvore>
  );
};

export default PlanteUmaArvore;
