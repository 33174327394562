import React from "react";
import { Link } from "react-router-dom";
import { ContainerLinks, LanguageSelect } from "./style";

const Links = () => {
    return (
        <ContainerLinks>
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/conheca-a-plantar">Conheça o projeto Plantar</Link>
            </li>
            <li>
                <Link to="/plante-uma-arvore">Plante uma árvore</Link>
            </li>
            <li>
                <Link to="/blog">Blog</Link>
            </li>
            <li>
                <Link to="/">Contato</Link>
            </li>
            <li>
                <a href="https://plataforma.projetoplantar.com.br" target="_blank" rel="noopener noreferrer">
                    Área de rastreio de árvores
                </a>
            </li>
            <div>
                <LanguageSelect>
                    <option value="pt-br">Português</option>
                    <option value="en">Inglês</option>
                    <option value="es">Espanhol</option>
                </LanguageSelect>
            </div>
        </ContainerLinks>
    );
};

export default Links;
