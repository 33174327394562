// PoliticasLGPD.js
import React from "react";
import { ContentLGPD } from "./style";
import { Col, Row } from "../../shared/components/Grids/style";

const PoliticasLGPD = () => {
  const dataAtual = new Date().toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <ContentLGPD>
      <div className="container">
        <Row>
          <Col size={12}>
            <article>
              <h2>Política de Privacidade - Projeto Plantar</h2>
              <p>
                <strong>Última atualização:</strong> {dataAtual}
              </p>

              <p>
                No <strong>Projeto Plantar</strong>, valorizamos a privacidade e
                a proteção dos dados pessoais de todos os nossos usuários. Esta
                Política de Privacidade descreve como coletamos, usamos,
                armazenamos e compartilhamos suas informações e como você pode
                controlar o uso de seus dados em nosso site.
              </p>

              <h3>1. Coleta de Dados</h3>
              <p>
                Ao acessar e utilizar o site <strong>Projeto Plantar</strong>,
                podemos coletar informações como:
              </p>
              <ul>
                <li>
                  Dados pessoais fornecidos diretamente por você, incluindo
                  nome, e-mail, telefone e endereço.
                </li>
                <li>
                  Informações de navegação, como páginas visitadas, tempo de
                  permanência no site e endereço IP.
                </li>
                <li>
                  Cookies e tecnologias semelhantes para melhorar sua
                  experiência de navegação e personalizar o conteúdo.
                </li>
              </ul>

              <h3>2. Uso de Dados</h3>
              <p>Os dados coletados são utilizados para:</p>
              <ul>
                <li>Melhorar a experiência do usuário em nosso site.</li>
                <li>
                  Fornecer informações e conteúdos relevantes sobre nossos
                  projetos e atividades.
                </li>
                <li>
                  Entrar em contato para compartilhar novidades, atualizações e
                  campanhas relacionadas ao Projeto Plantar.
                </li>
                <li>
                  Personalizar anúncios e conteúdos com base no seu perfil e
                  interesses.
                </li>
              </ul>

              <h3>3. Compartilhamento de Dados</h3>
              <p>
                Não compartilhamos suas informações pessoais com terceiros,
                exceto:
              </p>
              <ul>
                <li>Quando necessário para cumprir requisitos legais.</li>
                <li>
                  Quando for preciso para a execução de serviços essenciais,
                  como processamento de pagamentos ou envio de materiais.
                </li>
                <li>
                  Com parceiros de confiança que seguem esta Política de
                  Privacidade e ajudam a melhorar a experiência no site.
                </li>
              </ul>

              <h3>4. Segurança de Dados</h3>
              <p>
                Adotamos medidas de segurança para proteger suas informações
                pessoais contra acesso não autorizado, alteração ou divulgação.
                Contudo, não podemos garantir total segurança, e recomendamos
                que você proteja suas credenciais de login.
              </p>

              <h3>5. Cookies</h3>
              <p>Nosso site utiliza cookies para:</p>
              <ul>
                <li>Lembrar suas preferências e configurações.</li>
                <li>
                  Analisar o tráfego e o comportamento do usuário para melhorar
                  nossos serviços.
                </li>
                <li>
                  Oferecer anúncios relevantes, com base em seus interesses.
                </li>
              </ul>
              <p>
                Você pode ajustar as configurações do seu navegador para
                bloquear cookies, mas isso pode afetar a funcionalidade do site.
              </p>

              <h3>6. Seus Direitos</h3>
              <p>Você tem o direito de:</p>
              <ul>
                <li>
                  Acessar e solicitar uma cópia dos dados pessoais que mantemos
                  sobre você.
                </li>
                <li>
                  Solicitar a correção de dados incorretos ou incompletos.
                </li>
                <li>
                  Pedir a exclusão de seus dados pessoais em determinadas
                  circunstâncias.
                </li>
                <li>
                  Retirar seu consentimento para o uso de seus dados a qualquer
                  momento.
                </li>
              </ul>
              <p>
                Para exercer esses direitos, entre em contato conosco através do
                e-mail{" "}
                <a href="mailto:contato@projetoplantar.com">
                  contato@projetoplantar.com
                </a>
                .
              </p>

              <h3>7. Alterações na Política de Privacidade</h3>
              <p>
                Podemos atualizar esta Política de Privacidade periodicamente.
                Recomendamos que você revise esta página regularmente para estar
                sempre informado sobre nossas práticas de proteção de dados.
              </p>
            </article>
          </Col>
        </Row>
      </div>
    </ContentLGPD>
  );
};

export default PoliticasLGPD;
