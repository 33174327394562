import React from "react";
import { Helmet } from "react-helmet";
const Seo = ({
  titlePage,
  descriptionPage,
  urlPage,
  imagePage,
  keywordsPage,
}) => {
  return (
    <Helmet>
      <title>{titlePage}</title>
      <meta name="description" content={descriptionPage} />
      <meta name="keywords" content={keywordsPage} />
      <meta name="image" content={imagePage} />
      <meta name="rating" content="General" />
      <meta name="robot" content="all" />
      <meta name="Robots" content="index, follow" />
      <meta name="googlebot" content="all" />
      <meta name="distribution" content="Global" />
      <meta name="audience" content="all" />
      <meta name="revisit-after" content="1 day" />
      <meta
        name="Author"
        content="Velance (André Laurentino Rodrigues) - https://velance.com.br"
      />
      <meta property="og:title" content={titlePage} />
      <meta property="og:description" content={descriptionPage} />
      <meta property="og:image" content={imagePage} />
      <meta property="og:url" content={urlPage} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={titlePage} />
      <meta name="twitter:description" content={descriptionPage} />
      <meta name="twitter:image" content={imagePage} />
      <meta name="twitter:url" content={urlPage} />
      <meta name="twitter:creator" content="@your_twitter_handle" />
      <link rel="canonical" href={urlPage} />
    </Helmet>
  );
};

export default Seo;
