import styled from "styled-components";

const Content = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 61.5svh;
  .container {
    margin: 0 auto;
    padding: 20px;
    width: 99%;
  }
  @media (min-width: 768px) {
    .container {
      width: 90%;
    }
  }
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
`;

const TranslationPendingMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: #f3f3f3;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
  }
`;

const ContentBemVindo = styled.section`
  background-image: linear-gradient(to top, #0c3249 0%, #117b4e 100%);
  color: #fff;
  .container {
    margin: 0 auto;
    padding: 20px 0;
    width: 99%;
    h2 {
      font-size: 3em;
      margin-bottom: 10px;
    }
    p {
      font-size: 2em;
    }
  }
  @media (min-width: 768px) {
    .container {
      padding: 4em 0;
      width: 90%;
      h2 {
        font-size: 3em;
        margin-bottom: 40px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1400px) {
    .container {
      padding: 4em 0;
      width: 90%;
      h2 {
        font-size: 3em;
        margin-bottom: 40px;
      }
      p {
        font-size: 35px;
      }
    }
  }
`;

export { Content, ContentBemVindo, ContentSection, TranslationPendingMessage };
