import styled from "styled-components";
import fundoPlanteUmaArvore from "../../assets/imgs/fundo-plante-uma-arvore.png";

const ContentPlanteUmArvore = styled.section`
  display: flex;
  flex-direction: column;
`;

const BannerPlanteUmArvore = styled.section`
  background-image: linear-gradient(to top, #0c3249 0%, #117b4e 100%);
  color: #fff;
  width: 100%;
  .container {
    margin: 0 auto;
    width: 99%;
  }
  @media (min-width: 768px) {
    background-image: url(${fundoPlanteUmaArvore});
    background-repeat: no-repeat;
    background-size: 100%;
    color: #333;
    .container {
      padding: 10em 0;
      width: 90%;
      h2 {
        font-size: 3em;
      }
      h3 {
        font-size: 2em;
      }
      form{
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 100%;
        .container-input{
          select, input{
            padding: 1em;
            width: 100%;
          }
        }
        button{
            background-color: #117b4e;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-size: 1.5em;
            padding: 15px 1em;
            width: 100%;
        }
        .container-imagem{
          flex-direction: row;
          .card-plant{
            display: flex;
            flex-direction: column;
            width: 383px;
            h4{
              font-size: 1.5em;
              margin-bottom: 10px;
            }
            img{
              margin-bottom: 20px;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

export { ContentPlanteUmArvore, BannerPlanteUmArvore };
